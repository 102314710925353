
// $primary: black;
// $body-bg: #181818;
// $body-color: whitesmoke;
$secondary-color: red;
$secondary: rgb(54, 57, 57);
$text-primary: whitesmoke;
$text-secondary: #eeeeee;
$btn-secondary: rgb(54, 57, 57);

.links {
  padding-left: 6px;
}

.switcher select {
  width: 130px;
  height: 38px;
  background-color: #19854e;
  color:black;
  outline:none;
}

.carousel-button:hover {
  background-color: whitesmoke !important;
  color:#000 !important;
}

button.media-view-back-btn:hover {
  background-color: #19854e !important;
  color:whitesmoke !important;
}

.logo-pulse{
  animation: pulse 1s infinite;
  border-radius: 50%;
  width: 22em;
  padding:10px;
}

.dark .logo-pulse{
  content: url("./../src/components/main-view/src/logo.png");
  

}


.clear-search{
  width:30px !important;
  height:30px !important;
  margin-top: 0 !important;
    position: absolute;
    top: 103px;
    right: 0;
    outline: none;
    border: none;
    background-color: transparent;
    font-size:16px;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: #19854e;
  z-index: 1;
}




.dark .loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: #424242;
  z-index: 1;
}


@keyframes pulse{
  from{
    box-shadow: 0 0 0 0 rgba(198, 182, 179, 0.85);
  }

  to{
    box-shadow: 0 0 0 30px rgba(201, 48, 48, 0);
  }
}

.dark .switcher select {
  width: 130px;
  height: 38px;
  background-color: #000;
  color:whitesmoke;
  outline:none;
}

.like-button {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  padding: 10px;
  text-align: right;
  height: 48px;
  background-color: rgba(35, 34, 34, 0.3) !important;
}

.watched-button {
  text-align: right;
    width: 100%;
    height: 48px;
    padding: 10px;
    position: relative;
    top: -48px;
    right: 38px;
    background-color: transparent !important;
}

div.container-fluid{
  padding-left:0 !important;
  padding-right:0 !important;
  margin-left:0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}

.check-more-header-btn{
  background-color: #19854e;
  color:whitesmoke;
}

.dark .check-more-header-btn{
  background-color: #19854e;
  color:whitesmoke;
}



.check-more-header-btn:hover{
  background-color: whitesmoke;
  color:black;
  transition: all 0.2s ease;
}

.dark .check-more-header-btn:hover{
  background-color: whitesmoke;
  color:black;
  transition: all 0.2s ease;
}

div.container{
  padding-left:0 !important;
  padding-right:0 !important;
  margin-left:0 !important;
  margin-right: 0 !important;
  
}

.all-media-container {
  display: flex;
  width: 100%;
}

.app-container {
  width: 100%;
  margin: 0 auto;
}

.flexible-media {
  flex: 1;
  padding: 16px;
}
div.container-profile{
  margin-left:auto !important;
  margin-right: auto !important;
  // z-index:2;
}

.dark div.container-profile{
  margin-left:auto !important;
  margin-right: auto !important;
  background-color: #171616 !important;
  // z-index:0 !important;


}

div.welcome-player{
  z-index:-1;
}

div.alert-div.alert{
  // width:100% !important;
  margin-top:10px !important;
  margin-bottom: 10px !important;
  display: block !important;
}

.dark .container-search{
  background-color: #171616 !important;
  margin-left:0 !important;
  margin-right: 0 !important;

}

.container-search{
  // background-color: #171616 !important;
  margin-left:0 !important;
  margin-right: 0 !important;

}



.dark .body, .dark .container, .dark .root{
  background-color: #171616 !important;
} 

.dark span.navbar-toggler-icon{
  background:url('./images/navbar-toggler-icon.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 100% !important;
}

.profile-heading{
  color: #000; 
}

.dark .profile-heading{
  color: whitesmoke;
}

.form-label{
  color: #19854e; 
}

.dark .form-label{
  color: whitesmoke;
}

.form-submit-button{
  background-color: #19854e !important;
  outline: none !important;
  border:none !important;
}

// div.header{
//   margin-bottom: -16px !important;
// }

.load-spinner{
  display: none;
}


.show{
  display: inline !important;
}

.sign-load-spinner{
  display: none;
}


.sign-show{
  display: inline !important;
}

.update-load-spinner{
  display: none;
}

.update-span-hidden{
  display: none;
}

.sign-span-hidden{
  display: none;
}

.login-span-hidden{
  display: none;
}



.update-show{
  display: inline !important;
}

.search-input{
  color: #19854e !important;
  background-color: whitesmoke !important;
  font-style: italic;
  border-radius: 7px;
  width:100%;
  height: 36px;
  margin-top:-15px !important;
  margin-bottom:-15px !important;
}

div.cont{
  justify-content: space-evenly !important;
}

.justify-content-center{
  width:100%;
}

.dark .search-input{
  color: black !important;
  background-color: whitesmoke !important;
  font-style: italic;
  border-radius: 7px;
  width:100%;
  height: 36px;

}

.dark .form-submit-button{
  background-color: #000 !important;
  color: whitesmoke;
  outline: none !important;
  border:none !important;
}

.form-submit-button:hover{
  background-color: #248653 !important;
}

.footer-auth-icons{
  color: #248653;
}

.dark .footer-auth-icons{
  color: #000;
}

.footer-non-auth-icons{
  color: #248653;
}



.dark .footer-non-auth-icons{
  color: #000;
}
.dark .form-submit-button:hover{
  background-color: #1a1919 !important;
  color: whitesmoke;
}

.profile-data{
  color: #19854e; 
}

.dark .profile-data{
  color: #19854e;
}

.profile-tip{
  color: #000; 
}

.dark .profile-tip{
  color: whitesmoke;
}

.profile-tip-extra{
  color: #19854e; 
}

.dark .profile-tip-extra{
  color: #ffd43b;
}

.favorites-heading{
  color: #000; 
  background-color: #19854e;
}

.dark .favorites-heading{
  color: whitesmoke;
  background-color: #000;
}

.favorites-data{
  color: whitesmoke; 
}

.dark .favorites-data{
  color: #ffd43b;
}


.dark .profile-accordion-body{
background-color: #171616;

}




.dark .logo{
  content:url('./images/logo.png') !important;
  
}

.logo{
  content:url('./images/logo-light.png') !important;
  
}

div.h-100{
  height: 100vh !important;
}

div.h-120{
  height: 130vh !important;
}

.page-content-wrapper {
  margin: 0 auto auto auto !important;
}

html {
  position: relative;
  min-height: 100%;
  width: 100%;
}



button.info-button {
  font-size: 15px;
}

div#card-title {

  font-size: 15px;
}

div#card-title,
div.card-title {

  font-size: 15px;
}




/* scrollbar width */

::-webkit-scrollbar {
  width: 10px;
}

/* scrollbar Track */

::-webkit-scrollbar-track {
  background: -webkit-gradient(linear, left bottom, left top, from(#fbbc04)), to(#1d8b45);
  background: linear-gradient(0deg, #fbbc04 0%, #1d8b45 100%);
  border-radius: 10px;
}

/* scrollbar Handle */

::-webkit-scrollbar-thumb {
  opacity: 0;
  -webkit-box-shadow: 0px 0px 0px 100000vh black;
          box-shadow: 0px 0px 0px 100000vh black;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(111, 116, 113);
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.favorite-modal .modal-header {
  display: none;
}

.favorite-modal .modal-dialog {
  display: block !important;
  background: transparent !important;
  position: fixed !important;
  bottom: -35px !important;
  left: 0 !important;
  right: 0 !important;
  width: 96% !important;
  height: 100px !important;
}

.update-failed-modal .modal-header {
  display: none;
}

.update-failed-modal .modal-dialog {
  display: block !important;
  background: transparent !important;
  position: fixed !important;
  bottom: 60px !important;
  left: 0 !important;
  right: 0 !important;
  width: 96% !important;
  height: 100px !important;
}

//   .delete-modal .modal-header{
//     display:none;
//   }
.delete-modal .modal-dialog {
  display: block !important;
  background: transparent !important;
  position: fixed !important;
  top: 30% !important;
  left: 0 !important;
  right: 0 !important;
  width: 96% !important;
  height: 100px !important;
}

.got-it-button {
  position: absolute;
  top: 10px;
  right: 16px;
}

.got-it-button:hover {
  background-color: #1f8c49 !important;
  color: whitesmoke !important;
}

.dark .login-modal-body{
background-color:#000;
color:whitesmoke;
}

.dark .delete-title{
color: #913939 !important;
background-color: #000 !important;
}

.delete-title{
color: #913939 !important;
background-color: white !important;
}

.dark .delete-confirm{
color: whitesmoke !important;
background-color: #913939 !important;
}

.delete-confirm{
color: whitesmoke !important; 
background-color: #913939 !important; 
}

.dark .delete-cancel{
color: whitesmoke !important;
background-color: #1f8c49 !important;
}

.delete-cancel{
color: whitesmoke !important;
background-color: #1f8c49 !important;  
}

.dark .delete-confirm:hover{
  color: whitesmoke !important;
  background-color: #9a3030 !important;
  }
  
  .delete-confirm:hover{
  color: whitesmoke !important; 
  background-color: #9a3030 !important; 
  }
  
  .dark .delete-cancel:hover{
  color: whitesmoke !important;
  background-color: #2a834c !important;
  }
  
  .delete-cancel:hover{
  color: whitesmoke !important;
  background-color: #2a834c !important;  
  }

.dark .delete-footer{
  background-color: #171616 !important; 
}

.delete-footer{
  background-color: whitesmoke !important;
}

.modal-body{
background-color:whitesmoke;
color:#000;  
}

.modal-info-icon {
  padding-right: 6px;
}

.cancel-it-button {
  position: absolute;
  top: 10px;
  right: 100px;
}

div.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left:0;
  padding-right: 0;
  // padding-bottom: 20px !important;

  height: 240px;
  margin-top:-18px !important;
  background-color: #1f8c49 !important;
}

.page-content-wrapper {
  margin-bottom: 260px;
}

.dark div.footer {
  background-color: #000 !important;
  // margin-top:-18px !important;
  padding-left:0;
  padding-right: 0;
  // padding-bottom: 20px !important;
}

.footer-info {

  background-color: #1f8c49 !important;
  color: #fff;
}

.dark .footer-info {
  background-color: #000 !important;
  color: #fff;
}

.navigation {
  background-color: #1f8c49;
  color: hsl(0, 0%, 100%);
  width: 100%;
  justify-content: space-between !important;
  display: flex !important;
  padding-left:10px !important;
  padding-right:10px !important;
  z-index:2;
  // height:136px !important;

}

.dark .navigation {
  background-color: black !important;
  color: #fff;
  z-index:2;

}

.navbar-expand-lg .navigation {
  flex-direction: column !important;
}

.navbar-collapse.show {
  flex-direction: column !important;
}



.navbar-icons {
  color: #000;
}

.navbar-icons:hover {
  color: #212020;
  transition: all 0.2s ease;
  translate: 0px -4px;
}

.dark .navbar-icons {
  color: #1f8c49;
}

.dark .navbar-icons:hover {
  color: #2a8d50;
  transition: all 0.2s ease;
  translate: 0px -4px;
}

span.navbar-icons-span {
  color: white !important;
}

.dark span.navbar-icons-span {
  color: #fff !important;
}

.nav-drop .nav-link:hover, .nav-drop .nav-link:focus, .nav-drop .nav-link {
  color: white !important;
  background-color:#1f8c49;
  

  
}

.dark .nav-drop .nav-link:hover, .dark .nav-drop .nav-link:focus, .dark .nav-drop .nav-link {
  color: white !important;
  background-color:#000;
  

  
}

.nav-drop:hover, .dark .nav-drop:hover{
  translate: 0px -4px;
  transition: all 0.2s ease;

}

div.dropdown-menu.show{
  padding-top:0 !important;
  padding-bottom:0 !important;
}

.media-drop-item{
  padding:0 !important;
  margin:0 !important;
}

span.navbar-icons-span:hover {
  color: white !important;
  transition: all 0.2s ease;
  translate: 0px -4px;

}

.dark span.navbar-icons-span:hover {
  color: #fff !important;
  transition: all 0.2s ease;
  translate: 0px -4px;

}

.moon {
  font-size: 22px;


}

.sun {
  font-size: 22px;


}

.toggle_btn {
  background-color: #1f8c49;
  outline: none;
  border: none;
}

.dark .toggle_btn {
  background-color: #000;
  outline: none;
  border: none;
}

.dark button.got-it-button {
  background-color: transparent;
  color: #000;
}

button.got-it-button {
  background-color: #1f8c49;
  color: #fff;
}

.card {
  background-color: #1f8c49 !important;
  // display: flex !important;
  // height:100% !important;
  // // flex:1 !important;
}

.dark .card {
  background-color: #000 !important;
  // display: flex !important;
  // height:100% !important;

}

.item-view {
  background-color: #1f8c49 !important;
}

.dark .item-view {
  background-color: #000 !important;

}

.item-title {
  color: #000 !important;
}

.dark .item-title {
  color: #1f8c49 !important;

}

.item-subtitle {
  color: #282525 !important;
}

.dark .item-subtitle {
  color: #2e9054 !important;

}



.item-info {
  color: whitesmoke !important;
}

.dark .item-info {
  color: #ffd43b !important;

}

.item-view-info {
  color: whitesmoke !important;
}

.dark .item-view-info {
  color: whitesmoke !important;

}


.dark .image-info{
  content: url("./images/info.svg"); 
}

.dark .image-clock{
  content: url("./images/clock-2.svg"); 
}

.dark .image-work{
  content: url("./images/work.svg"); 
}

.dark .image-birthplace{
  content: url("./images/european-name-badge.svg"); 
}

.image-info{
  content: url("./images/info-light.svg"); 
}

.image-clock{
  content: url("./images/clock-2-light.svg"); 
}

.image-work{
  content: url("./images/work-light.svg"); 
}

.image-birthplace{
  content: url("./images/european-name-badge-light.svg"); 
}


.dark .image-info{
  content: url("./images/info.svg"); 
}

.image-actors{
  content: url("./images/actors-light.svg"); 
}

.dark .image-actors{
  content: url("./images/actors.svg"); 
}

.image-flag{
  content: url("./images/flag-light.svg"); 
}

.dark .image-flag{
  content: url("./images/flag.svg"); 
}

.image-signature{
  content: url("./images/signature-light.svg"); 
}

.dark .image-signature{
  content: url("./images/signature.svg"); 
}

.image-sword{
  content: url("./images/sword-light.svg"); 
}

.dark .image-sword{
  content: url("./images/sword.svg"); 
}

.image-repeat{
  content: url("./images/repeat-light.svg"); 
}

.dark .image-repeat{
  content: url("./images/repeat.svg"); 
}
.image-family-restroom{
  content: url("./images/family-restroom-light.svg"); 
}

.dark .image-family-restroom{
  content: url("./images/family-restroom.svg"); 
}

.image-globe{
  content: url("./images/globe-light.svg"); 
}

.dark .image-globe{
  content: url("./images/globe.svg"); 
}

.accordion-title{
  color: #000;
  background-color: whitesmoke; 
}

.dark .accordion-title{
  color: whitesmoke;
  background-color: #000;}

  .alert{
    text-transform: uppercase;
    color: whitesmoke !important;
    background-color: #2e9054 !important; 
  }
  
  .dark .alert{
    text-transform: uppercase;
    color: whitesmoke !important;
    background-color: #000 !important;}

.accordion-lis{
  color: #000;

}

.dark .accordion-lis{
  color: whitesmoke;
}

.accordion-body{
  padding-left: 0 !important;
  padding-right:0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
}

.accordion-item{
  border-bottom: 0 !important;
  border-top: 0 !important;
}

.image-clock-light{
  content: url("./images/clock-light.svg"); 
}

.dark .image-clock-light{
  content: url("./images/clock.svg"); 
}

.image-director{
  content: url("./images/director-light.svg"); 
}

.dark .image-director{
  content: url("./images/director.svg"); 
}

.image-imdb{
  content: url("./images/imdb-light.svg"); 
}

.dark .image-imdb{
  content: url("./images/imdb.svg"); 
}

.image-rotten{
  content: url("./images/rotten-tomatoes-light.svg"); 
}

.dark .image-rotten{
  content: url("./images/rotten-tomatoes.svg"); 
}



@media screen and (max-width:991.5px) {
  .navbar-brand{
    align-self: center !important;
    margin-right:0 !important;
  }
  .clear-search{
  background-color: #0000;
  border: none;
  outline: none;
  font-size: 16px;
  position: relative;
  top: -15px;
  left: 94%;
  height: 30px !important;
  margin-top: 0;
}
div.welcome-player{
  width: 100% !important;
  height: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: fixed !important;
  inset: 0 !important;
}
  div.welcome-player video{
    width: auto !important;
    height: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    position: absolute !important;
    inset: 0 !important;
 
}



}
@media screen and (min-width:992px) {
  
  .navbar-expand-lg .navigation, .navbar-collapse.show{
    flex-direction: row !important;
    z-index:3;
  }
  div.welcome-player{
    position: fixed !important;
    padding-left: 0px;
    padding-right: 0px;
    width: 100% !important;
    height: auto !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
  }
}

@media screen and (max-width:1460px){
 
}



@media screen and (max-width:714px) {
.clear-search{
  background-color: #0000;
  border: none;
  outline: none;
  font-size: 16px;
  position: relative;
  top: -15px;
  left: 92%;
  height: 30px !important;
  margin-top: 0;
}
}
@media screen and (max-width:767px) {

  div.container, .dark div.container{
  margin: 0 auto 240px auto !important;
}
div.container.main-view-div, .dark div.container.main-view-div{
  margin: 0 auto 0 auto !important;
}
}
@media screen and (max-width:770px) {
  .app-container {
    width: 100%;
    margin: 0 auto;
  }

  .carousel-caption-header.pb-3 {
    margin: 0px;
    font-size: 14px;
    padding: 0;
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
    // font-size: 14px;
    display: none;
  }

  .carousel-caption-paragraph {
    margin: 0;
    padding: 0;
    font-size: 12px;
    display: none;
  }

  .carousel-button {
    width: 100px !important;
    height: 30px !important;
    font-size: 10px !important;
  }

  

  div.container-profile, .dark div.container-profile{
    margin: 0 auto 0 auto !important;
    padding-bottom: 36px;
  }
}

@media screen and (max-width:1768px) {
 
}
  @media screen and (max-height:620px) {

div.h-100{
  height: 270vh !important;
}

div.h-120{
  height: 340vh !important;
}

div.welcome-player{
  padding-left: 0;
  padding-right: 0;
  width: 100% !important;
  height: auto !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
}
  div.welcome-player video{
    width: 100% !important;
    height: auto !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    position: fixed !important;
    inset: 0 !important;
    top: 0 !important;

 
}
div.container-profile.login-view, .dark div.container-profile.login-view {
  margin-bottom: 290px;
  height: 100%;
}

}
@media screen and (max-width:400px) {
  .app-container {
    width: 100%;
    margin: 0 auto;
  }

  .carousel-caption-header.pb-3 {
    margin: -10px;
    font-size: 10px;
    padding: 0;
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
    // font-size: 14px;
    display: none;
  }

  .carousel-caption-paragraph {
    margin: 0;
    padding: 0;
    font-size: 8px;
    display: none;
  }

  .carousel-button {
    padding: 5px !important;
    width: 90px !important;
    height: 26px !important;
    font-size: 10px !important;
  }
}

@import '~bootstrap/scss/bootstrap.scss';